<template>
  <base-modal
    modal-title="Collaborators List - Assigned Collaborators"
    @hidden="resetCollaboratorsTable()"
    @visible="fetchCollaborators()"
  >
    <template #modalBody>
      <div class="px-6 py-5">
        <brand-assurance-table
          v-model="collaboratorsData.tableData"
          :show-check-box-column="false"
          root-element-class="mb-5"
        />
        <div class="flex justify-center">
          <base-button
            class="mr-3 py-1"
            variant="btn-primary"
            text="Close"
            @click="handleCancel()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent, reactive, computed } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'CollaboratorsModal',

    components: {
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue'))
    },

    props: {
        collabId: {
            type: String,
            required: true,
            description: 'collaboration id used for fetching collaborators'
        }
    },

    emits: [
        'hide'
    ],

    setup (props, { emit }) {
        const store = useStore();

        // modal logic
        const handleCancel = () => {
            emit('hide');
            resetCollaboratorsTable();
        };

        // collaborators table logic
        const collaboratorsData = reactive({
            tableData: {
                columns: [
                    {
                        label: 'Users Name',
                        key: 'userName'
                    },
                    {
                        label: 'User Id',
                        key: 'userId'
                    }
                ],
                data: []
            },
            page: 1,
            limit: 5,
            pageCount: 0
        });
        const resetCollaboratorsTable = () => {
            collaboratorsData.tableData.data = [];
            collaboratorsData.tableData.pageCount = 0;
            collaboratorsData.tableData.page = 1;
        };
        const fetchedCollabs = computed(() => store.getters['baCollaborations/getCollaborationsList']);
        const fetchCollaborators = () => {
            if (fetchedCollabs.value?.data?.length > 0) {
                updateCollaboratorsData();
            }
        };
        const updateCollaboratorsData = () => {
            const matchedCollab = fetchedCollabs.value.data.find(collab => collab.collaborationId === props.collabId);
            collaboratorsData.tableData.data = matchedCollab ? matchedCollab.collaborators : [];
        };

        return {
            // action
            handleCancel,
            // collaborators table
            collaboratorsData,
            resetCollaboratorsTable,
            fetchCollaborators
        };
    }
};
</script>
